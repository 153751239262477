<template>
	<section class="app-config-protocol-wrap" v-loading="isLoading">
		<div v-if="protocolData">
			<!-- 用户协议 -->
			<div class="label-box">
				<div>
					<LableTitle title='关于平台' num='1' :showTitle="true"></LableTitle>
				</div>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='PLATFORM_ABOUT_US'></j-editor>
				</div>
			</div>
			<!-- 使用帮助 -->
			<div class="label-box">
				<LableTitle title='用户服务协议' num='2' :showTitleUser="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='PLATFORM_SERVICE_AGREEMENT'></j-editor>
				</div>
			</div>
			<!-- 济南繁星回收产品介绍 -->
			<div class="label-box">
				<LableTitle title='济南繁星回收产品介绍' num='3' :showTitleProduct="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='PRODUCT_INTRODUCTION'></j-editor>
				</div>
			</div>
			<!-- 购买及使用须知 -->
			<div class="label-box">
				<LableTitle title='购买及使用须知' num='4' :showTitlePay="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='PRODUCT_PAY'></j-editor>
				</div>
			</div>
			<!-- 首页拍照规范 -->
			<div class="label-box">
				<LableTitle title='首页拍照规范' num='5'></LableTitle>
				<div class="flex-box">
					<!-- type是获取--配置数据接口返回的字段 -->
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Photo_Rule'></j-editor>
				</div>
			</div>
			<!-- 如何获取及使用繁星码 -->
			<div class="label-box">
				<LableTitle title='如何获取及使用繁星码' num='6' :showTitleAgree="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='PRODUCT_AGREEMENT'></j-editor>
				</div>
			</div>
			<!-- 门店端-支付申请说明 -->
			<div class="label-box">
				<LableTitle title='门店端-支付申请说明' num='7' :showTitleApply="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Company_Apply_Online_Pay'></j-editor>
				</div>
			</div>
			<!-- 门店端-支付开启说明 -->
			<div class="label-box">
				<LableTitle title='门店端-支付开启说明' num='8' :showTitleOpen="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Company_Open_Online_Pay'></j-editor>
				</div>
			</div>
			<!-- 回收端-线上支付说明 -->
			<div class="label-box">
				<LableTitle title='回收端-线上支付说明' num='9' :showTitlePayExplain="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Merchant_Online_Pay'></j-editor>
				</div>
			</div>
			<!-- 门店端-商户提现协议 -->
			<div class="label-box">
				<LableTitle title='门店端-商户提现协议' num='10' :showTitleCashExplain="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Company_Cash_Out_Agree'></j-editor>
				</div>
			</div>
			<!-- 门店端-奖励提现协议 -->
			<div class="label-box">
				<LableTitle title='门店端-奖励提现协议' num='11' :showTitleAwardExplain="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Company_Staff_Award_CashOut_Rule'></j-editor>
				</div>
			</div>
			<!-- 回收端-物流申请海报 -->
			<div class="label-box">
				<LableTitle title='回收端-物流申请海报' num='12' :showTitleLogisticsExplain="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Merchant_Apply_Logistics_Poster'></j-editor>
				</div>
			</div>
			<!-- 回收端-物流服务协议 -->
			<div class="label-box">
				<LableTitle title='门店端-物流服务协议' num='13' :showTitleServeExplain="true"></LableTitle>
				<div class="flex-box">
					<j-editor @handleEditContent="handleEditContent" @handleSettingEditContent="handleSettingEditContent"
						type='Store_Apply_Logistics_Serve_Letter'></j-editor>
				</div>
			</div>
			<!-- 繁星追差须知 -->
			<div class="label-box">
				<LableTitle title='繁星追差须知' num='14' :Startrackingdifference="true"></LableTitle>
				<div class="gengTime">
					<p style="margin-right: 100px;">最近更新人：{{ (zhandata.adminName || "-") + "-" + (zhandata.adminAcc || "")
					}}</p>
					<p style="margin-right: 100px;">最近更新时间：{{ zhandata.updateTime || "--" }}</p>
					<el-button size="small" @click="updatezhui" type="primary">提交</el-button>
				</div>
				<div class="flex-box">
					<j-editor :key="999" @handleEditContent="handleEditContent"
						@handleSettingEditContent="handleSettingEditContent" type='Apr_Know'></j-editor>
				</div>
			</div>
			<div class="bottom-operate">
				<el-button @click="handerClose()" style="margin-top:100px">返回</el-button>
				<el-button type="primary" @click="modifyConfig">提交</el-button>
			</div>
		</div>
	</section>
</template>

<script>
import _api from "@/utils/request";
import LableTitle from './label-title.vue'
import editor from './editor'
export default {
	data() {
		return {
			protocolData: '',
			dropData: {
				"Apr_Know": ""
			},
			zhandata: {},
			isLoading: false,
		}
	},
	created() {
		this.getProtocolConfig()
	},
	methods: {
		getProtocolConfig() {
			this.isLoading = true
			const vo = {
				terminal: 'mobile',
				module: 'protocol'
			}
			// 获取--配置数据
			_api.ResourcesConfig(vo).then(res => {
				this.isLoading = false
				if (res.code === 1) {
					console.log(res.data, Object.keys(res.data))
					this.protocolData = {}
					Object.keys(res.data).forEach(key => {
						console.log(key, res.data[key].sysValue)
						this.protocolData[key] = res.data[key].sysValue
					})
					_api.getAprConfig().then(res => {
						this.zhandata = res.data
					})
					console.log(this.protocolData)
				}
			})
		},
		handerClose() {
			this.$router.push({ path: "/systemManage/StaffManage" })
		},
		// 修改配置图片
		modifyConfig() {
			const vo = {
				data: this.protocolData,
				terminal: 'mobile',
				module: 'protocol'
			}
			_api.ResourcesEdit(vo).then(res => {
				this.isLoading = false
				if (res.code === 1) {
					this.getProtocolConfig()
					this.$message.success('提交成功')
				}
			})
		},
		updatezhui() {
			const vo = {
				// data: this.dropData,
				data: {
					Apr_Know: this.protocolData["Apr_Know"]
				},
				terminal: 'mobile',
				module: 'protocol'
			}
			_api.updateAprConfig(vo).then(res => {
				this.isLoading = false
				if (res.code === 1) {
					this.$message.success('提交成功')
					this.getProtocolConfig()
					_api.getAprConfig().then(res => {
						this.zhandata = res.data
						// this.dropData["Apr_Know"] = res.data.value
					})
				}
			})
		},
		handleEditContent(html, type) {
			// if (type == "Apr_Know") {
			// 	this.dropData[type] = html
			// } else {
			this.protocolData[type] = html
			// }
			this.$forceUpdate()
		},
		handleSettingEditContent(set, type) {
			console.log('handleSettingEditContent', this.protocolData)
			console.log(set, type)
			set.html(this.protocolData[type])
		},
		// dropSettingEditContent(set, type) {
		// 	console.log('dropSettingEditContent', this.dropData)
		// 	console.log(set, type)
		// 	set.html(this.dropData[type])
		// },
	},

	components: {
		LableTitle,
		[editor.name]: editor
	}
}
</script>
<style lang="scss" scoped>
.app-config-protocol-wrap {
	.label-box {
		margin-bottom: 40px;
	}

	.flex-box {
		display: flex;
		padding-left: 100px;
		width: 1000px;
	}

	.bottom-operate {
		// border-top: 1px solid #ccc;
		padding: 20px 200px 60px;

		/deep/.el-button {
			padding: 8px 13px;
		}
	}
}

.gengTime {
	display: flex;
	align-items: center;
	padding-left: 100px;
	width: 1000px;
	margin-bottom: 20px;
}
</style>
