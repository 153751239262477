<template>
  <div class="Resources">
    <div class="case-title">
      <div class="icon_title">
        <div>
          <img class="case-title_icon" src="@/assets/images/imgAll/icon_zypz_@2x.png" alt />
        </div>
        <div class="case-title_max">系统动态数据配置</div>
      </div>
    </div>
    <div class="tab_click">
      <div class="tabRound">
        <div :class="clickTab == 1 ? 'left' : 'right'" @click="getItme(1)">其他配置</div>
        <div :class="clickTab == 2 ? 'left' : 'right'" @click="getItme(2)">协议说明</div>
      </div>
      <div v-if="this.clickTab == 1" class="orther_style">
        <div class="input_phone">
          <div class="toptext">繁星门店端小程序（独立部署小程序需单独配置）</div>
          <LableTitle title='平台客服电话' num='1' :storeplantservice="true" :showTitle="false"></LableTitle>
          <div class="form-item">
            <div style="font-size:14px;margin-right:12px;color:#666">客服电话</div>
            <el-input placeholder="请输入内容" v-model="phone" size="small" clearable>
            </el-input>
          </div>
          <LableTitle title='客服微信号' num='2' :storewehatservice="true" :showTitle="false"></LableTitle>
          <div class="form-item">
            <div style="font-size:14px;margin-right:12px;color:#666">微信号</div>
            <el-input placeholder="请输入内容" v-model="wechatNum" size="small" clearable>
            </el-input>
          </div>
          <LableTitle title='客服微信二维码' num='3' :storeQrcodeservice="true" :showTitle="false"></LableTitle>
          <div class="form-item img">
            <div style="font-size:14px;margin-right:12px;color:#666;margin-bottom:20px">仅支持上传一张图片，大小不超过500k</div>
            <ElImgUpload key="QRCode" :exist-image="QRCode" @handleChange="handleChange" />
          </div>
          <div style="padding-left:50px">
            <el-button @click="handerClose()" size="small" style="margin-top:20px">返回</el-button>
            <el-button @click="handerSure()" type="primary" size="small" style="margin-top:20px">提交</el-button>
          </div>
        </div>
        <div style="margin-left: 150px;" class="input_phone">
          <div class="toptext">济南繁星回收端小程序</div>
          <LableTitle title='平台客服电话' num='1' :merchatplantservice="true" :showTitle="false"></LableTitle>
          <div class="form-item">
            <div style="font-size:14px;margin-right:12px;color:#666">客服电话</div>
            <el-input placeholder="请输入内容" v-model="merphone" size="small" clearable>
            </el-input>
          </div>
          <LableTitle title='客服微信号' num='2' :merchatwehatservice="true" :showTitle="false"></LableTitle>
          <div class="form-item">
            <div style="font-size:14px;margin-right:12px;color:#666">微信号</div>
            <el-input placeholder="请输入内容" v-model="merwechatNum" size="small" clearable>
            </el-input>
          </div>
          <LableTitle title='客服微信二维码' num='3' :merchatQrcodeservice="true" :showTitle="false"></LableTitle>
          <div class="form-item img">
            <div style="font-size:14px;margin-right:12px;color:#666;margin-bottom:20px">仅支持上传一张图片，大小不超过500k</div>
            <ElImgUpload key="QRCode" :exist-image="merQRCode" @handleChange="handlemerChange" />
          </div>
          <div style="height: 50px;">
           
          </div>
        </div>
      </div>
      <div v-if="this.clickTab == 2">
        <ResourcesEditor></ResourcesEditor>
      </div>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { formValidateMobileLandline } from "@/utils/form-validate";
import ElImgUpload from "@/components/global/components/elements/upload-element";
import ResourcesEditor from './component/ResourcesEditor'
import LableTitle from './component/label-title'
export default {
  components: { ResourcesEditor, LableTitle, ElImgUpload },
  data() {
    return {
      clickTab: "1",
      initData: {
        phone: "",
      },
      QRCode: "",
      phone: "",
      wechatNum: "",
      merQRCode: "",
      merphone: "",
      merwechatNum: "",
      formItemList: [{
        key: "phone",
        type: "input",
        labelName: "客服电话",
        placeholder: "请输入客服电话"
      }],
      formRules: {
        phone: [
          {
            required: true,
            validator: formValidateMobileLandline,
            trigger: "blur"
          }
        ],
      }
    };
  },
  created() {
    this.getItme(1)
  },
  methods: {
    getItme(index) {
      this.clickTab = index
      if (index === 1) {
        const vo = {
          terminal: 'mobile',
          module: 'website'
        }
        _api.ResourcesConfig(vo).then(res => {
          if (res.code === 1) {
            //门店
            this.phone = res.data.PLATFORM_SERVICE_PHONE.sysValue || ""
            this.wechatNum = res.data.Platform_Wechat.sysValue || ""
            this.QRCode = res.data.Platform_Wechat_QRCode.sysValue || ""
           //回收 PLATFORM_SERVICE_PHONE_Mer  平台客服电话（回收端）Platform_Wechat_Mer 客服微信号（回收端）Platform_Wechat_QRCode_Mer 客服微信二维码（回收端）
            this.merphone = res.data. PLATFORM_SERVICE_PHONE_Mer.sysValue || ""
            this.merwechatNum = res.data.Platform_Wechat_Mer.sysValue || ""
            this.merQRCode = res.data.Platform_Wechat_QRCode_Mer.sysValue || ""
            console.log(this.initData.phone)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    //提交
    handerSure() {
      const vo = {
        data: {
          PLATFORM_SERVICE_PHONE: this.phone,
          Platform_Wechat: this.wechatNum,
          Platform_Wechat_QRCode: this.QRCode,
          PLATFORM_SERVICE_PHONE_Mer :this.merphone,
          Platform_Wechat_Mer :this.merwechatNum,
          Platform_Wechat_QRCode_Mer :this.merQRCode,
        },
        module: 'website',
        terminal: 'mobile'
      }
      _api.ResourcesEdit(vo).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
        }
      })
    },
    // 返回
    handerClose() {
      this.$router.push({ path: "/systemManage/StaffManage" })
    },
    handleChange(e) {
      console.log(e);
      this.QRCode = e
    },
    handlemerChange(e) {
      console.log(e);
      this.merQRCode = e
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.Resources {
  width: 100%;
  min-height: 800px;
  background: #ffffff;
  box-shadow: 0px 8px 31px 0px rgba(20, 49, 127, 0.08);
  border-radius: 20px;

  .tabRound {
    cursor: pointer;
    margin-bottom: 40px;
    width: 280px;
    height: 32px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 16px;
    display: flex;

    .left {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      background: #0981FF;
      border-radius: 16px;
    }

    .right {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 34px;
      height: 32px;
      border-radius: 16px;
    }
  }

  .case-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .icon_title {
      display: flex;

      .case-title_icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .case-title_max {
        display: inline-block;
        width: 200px;
        height: 17px;
        font-size: 18px;
        font-family: FZLanTingHei-B-GBK;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
  }

  .tab_click {
    padding: 20px 0 50px 50px;

    .input_phone {
      /deep/ .el-input {
        width: 340px;
      }
    }
  }

  .form-item {
    width: 460px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 50px;
    margin-bottom: 20px;
  }

  .form-item.img {
    display: block;
    justify-content: flex-start;
  }
}

.orther_style {
  display: flex;
  align-items: center;
}

.toptext {
  color: #0981FF;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>